$primary: #f0f3fa;
$blue: #2551bf;
$dark-blue: #041b4a;

:root {
  --dark-blue: #00194a;
  --blue: #2551bf;
  --light-gray: #F5F7FC;
  --lighter-gray: #E0E6F5;
  --light-blue: #DBE2F4;
}
