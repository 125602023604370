// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-succesteam-primary: mat.define-palette(mat.$indigo-palette);
$angular-succesteam-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-succesteam-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-succesteam-theme: mat.define-light-theme((
  color: (
    primary: $angular-succesteam-primary,
    accent: $angular-succesteam-accent,
    warn: $angular-succesteam-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-succesteam-theme);

/* You can add global styles to this file, and also import other style files */
@import '~primeicons/primeicons.css';

@import "./main-variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");


*,
*::before,
*::after {
  //margin: 0;
  //padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}


html {
  // this defines what 1 rem is
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
}

body {
  width: 100%;
  height: 100vh;
  background-color: $primary !important;
  font-family: "Poppins", sans-serif;
}

//
///* Track */
//::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 5px grey;
//  border-radius: 10px;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background: rgba(131, 131, 131, 0.66);
//  border-radius: 10px;
//}


page-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  //height: 8.2vh;
  background-color: #FFFFFF;
  border-radius: 35px 0 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

label {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 12px;
}

.input-container {
  margin-bottom: 15px;
}

.form-check-input {
  height: 20px;
  width: 20px;
  padding: 0;
}

.form-check-input:checked {
  background-color: #00bd82;
  border-color: #00bd82;
}

.form-control,
.form-check-input:focus {
  font-size: 1.4rem;
  box-shadow: none;
}

.btn-gray {
  background-color: #e3e8f5 !important;
  color: #4c70ca !important;
  border-radius: 15px;
  height: 55px;
  font-size: 1.6rem;
  width: 175px;
  text-align: center;
}

.btn-hallow {
  background-color: #ffffff;
  color: #2551BF;
  border: #2551bf 1px solid;
  border-radius: 15px;
  height: 55px;
  font-size: 1.6rem;
  padding-left: 32px;
  padding-right: 32px;
}

.btn-text {
  background-color: transparent;
  color: #4c70ca;
  border-radius: 15px;
  height: 55px;
  font-size: 1.6rem;
  padding-left: 32px;
  padding-right: 32px;
}

.btn-blue {
  border-radius: 15px;
  background-color: #2551bf;
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
  height: 55px;
  //max-width: fit-content;
  width: 100%;
}
.btn-blue hover{
  color: #ffffff;
}

.btn-red {
  border-radius: 15px;
  background-color: red;
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
  height: 55px;
  //max-width: fit-content;
  border: transparent;
  width: auto;
  padding: 0 3rem;
  margin: 0 2rem;
}

.green-btn-text {
  color: #27C452;
  border: #27C452 1px solid;
  border-radius: 1.7rem;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.mat-card {
  padding: 0;
  border-radius: 20px;
  //box-shadow: 50px 40px 80px #A0B0CA8F !important;
  overflow: auto;
}

.mat-card-content {
  border-radius: 0 0 20px 20px;
  background-color: #F5F7FC;
  padding: 20px;
  margin-bottom: 0;
}

.mat-card-footer {
  margin: 0;
}

.onboarding-header .mat-card-header-text {
  display: none;
}

ngx-dropzone {
  border: 1px dashed #AFBDDF;
  max-width: 150px;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-color: #F5F7FC;
  margin-left: auto;

  @media (max-width: 767px) {
    margin: 20px auto 0;
  }
}

ngx-dropzone-label {
  font-size: 1.2rem;
  padding: 0 20px;
}

ngx-dropzone-preview {
  min-height: 100px !important;
  height: 100% !important;
  margin: 0 auto !important;
  max-width: 225px !important;
  width: 100% !important;
}

ngx-dropzone-image-preview {
  height: auto !important;
  margin: 0 !important;
}

ngx-dropzone-image-preview img {
  height: 148px;
  width: 148px;
  aspect-ratio: 1;
  object-fit: cover;
  background-position: center;
  border-radius: 50% !important;
}

ngx-dropzone-preview ngx-dropzone-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::placeholder, .mat-select-placeholder {
  color: #BFBFBF;
  font-size: 1.4rem;
  font-weight: 400;
}

.ng-autocomplete {
  width: 100%;
  padding: 0 36px 0 0;
  border: none;
  border-bottom: 1px solid #E8E8E8;
}

.autocomplete-container {
  box-shadow: none !important;
  height: 50px !important;
}

.autocomplete-container .input-container input {
  font-size: 1.6rem !important;
  padding: 0 12px !important;
}

.mat-select.form-select {
  border: 1px solid #E8E8E8;
  height: 50px;
  border-radius: 7px;
  background-image: none;
  padding: 6px 12px;
}

.mat-select-trigger {
  line-height: 36px;
  height: 38px;
}

.p-carousel-indicator ng-star-inserted {
  border-radius: 50px;
}

.pi-chevron-right::before, .pi-chevron-left::before {
  font-family: 'primeicons', sans-serif !important;
}

.p-link {
  border-radius: 50px;
}

.mat-chip-list-wrapper input.mat-input-element {
  border: none;
  height: auto;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  margin: 0;
  border: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  border-radius: 7px !important;
  min-height: 145px;
  padding: 15px !important;
}

.ngx-autocomplete-container input {
  border: none;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  border-radius: 7px;
}

ngx-intl-tel-input {
  border: none;
  height: 50px;
  border-radius: 7px;
  padding: 0;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  padding: 6px 12px;
  height: 50px;
  border-radius: 7px;
  border: 1px solid #E8E8E8;
}

#phone {
  //border: 1px solid #E8E8E8;
  height: 50px;
  border:transparent;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  background: transparent;
  border-radius: 7px;
}
#phone:focus input:focus {
  outline: none;
}
.ngx-autocomplete-container .suggestions-container ul {
  max-height: 150px !important;
}

.ngx-autocomplete-container .suggestions-container ul li a {
  padding: 6px 12px !important;
  font-size: 14px !important;
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  font-size: 14px !important;
  padding: 6px 12px !important;
}

.ngx-autocomplete-container .input-container {
  margin: 0 !important;
}

.mat-select-value-text {
  font-size: 14px;
}

.blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.p-top {
  margin-top: 3px;
}

.custom-slider .ngx-slider .ngx-slider-bar-wrapper {
  @media (max-width: 767px) {
    margin-top: -29px !important;
    width: 95%;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.user-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.user-avatar {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 50%;
}

.page-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  margin: auto;
  padding: 20px 30px;
  border-radius: 35px 0 0 0;
  background-color: #FFFFFF;

  h2 {
    vertical-align: center;
  }
}

.settings-big-card {
  padding: 40px 50px 0 50px;
  border-radius: 25px;
}

p.light {
  font-size: 1.6rem;
}

.team-name-span {
  border-radius: 50px;
  background-color: #E1E8F9;
  font-size: 14px;
  margin-left: 10px;
  padding: 5px;

  i {
    color: #2551bf;
  }
}

.modal-content {
  border-radius: 1.4rem;
}

.primary-btn {
  display: block;
  border-radius: 15px;
  background-color: var(--blue);
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
  height: 55px;
  width: 175px;
}

.tip {
  border-radius: 10px;
  padding: 9px 25px;
  margin-top: 15px;
  font-size: 1.2rem;
  background-color: rgb(255, 193, 7, 0.2);
}

.feedback-pulse {
  box-shadow: 0 0 0 0 rgba(0, 189, 130, 1);
  animation: feedback-member 2s infinite;
  cursor: pointer;
}

.feedback-pulse:hover {
  animation: none;
  box-shadow: none !important;
}

@keyframes feedback-member {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 189, 130, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 189, 130, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 189, 130, 0);
  }
}

.member-pulse {
  box-shadow: 0 0 0 0 rgba(38, 81, 191, 1);
  animation: pulse-member 2s infinite;
}

.member-pulse:hover {
  animation: none;
  box-shadow: none !important;
}

@keyframes pulse-member {
  0% {
    box-shadow: 0 0 0 0 rgba(38, 81, 191, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(38, 81, 191, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(38, 81, 191, 0);
  }
}

.leader-pulse {
  box-shadow: 0 0 0 0 rgba(253, 164, 0, 1);
  animation: pulse-leader 2s infinite;
}

.leader-pulse:hover {
  animation: none;
  box-shadow: none !important;
}

@keyframes pulse-leader {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 164, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(253, 164, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(253, 164, 0, 0);
  }
}


.reminder-pulse {
  box-shadow: 0 0 0 0 rgba(255,60,120, 1);
  animation: reminder-member 2s infinite;
  cursor: pointer;
}

//.reminder-pulse:hover {
//  animation: none;
//  box-shadow: none !important;
//}

@keyframes reminder-member {
  0% {
    box-shadow: 0 0 0 0 rgba(255,60,120, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255,60,120, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255,60,120, 0);
  }
}

.fl-card-description {
  font-size: 1.6rem;
}

.cursor-pointer {
  cursor: pointer;
}

.badge-check {
  background-color: #27C452;
  background-size: cover;
  border-radius: 5rem;
  width: 2.2rem;
  float: right;
  margin-top: -.5rem;
  margin-right: .5rem;

  img {
    width: 1.4rem;
    margin: .5rem;
    padding: .1rem .2rem .1rem 0;
  }
}

.badge-warning {
  background-color: #FDA400;
  background-size: cover;
  border-radius: 5rem;
  width: 2.2rem;
  float: right;
  margin-top: -.5rem;
  margin-right: .5rem;

  img {
    width: 14px;
    margin: .5rem;
    padding-right: .2rem;
  }
}
.badge-warning-single-team {
  background-color: #FDA400;
  background-size: cover;
  border-radius: 5rem;
  width: 2.2rem;
  height: 2.2rem;
  margin-top: -.5rem;
  margin-right: .5rem;

  img {
    width: 14px;
    margin: -5px 0.5rem 7px;
    padding-right: .2rem;
  }
}


.tooltip-style {
  font-size: 1.4rem;

  .p-tooltip-text {
    width: auto;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: 1rem;
    //padding: .5rem 2rem;
  }
}

.tooltip-score {
  font-size: 1.4rem;

  .p-tooltip-text {
    width: 40rem;
    margin-left: -100%;
    text-align: center;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: 1rem;
    //padding: .5rem 2rem;
  }
}

.response-distribution-tooltip {
  font-size: 1.4rem;

  .p-tooltip-text {
    width: 30rem;
    margin-left: -70%;
    text-align: center;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: 1rem;
    //padding: .5rem 2rem;
  }
}

.trends-tooltip {
  font-size: 1.4rem;

  .p-tooltip-text {
    width: 63.4rem;
    margin-left: -203%;
    text-align: center;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: 1rem;
    //padding: .5rem 2rem;
  }
}

.member-email-tooltip {
  max-width: none;
  font-size: 1.4rem;

  .p-tooltip-text {
    width: auto;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: .5rem 1.6rem;
  }
}

.member-activity-tooltip {
  max-width: 300px;
  font-size: 1.4rem;

  .p-tooltip-text {
    text-align: center;
    width: auto;
    background-color: #fafbfd;
    color: black;
    border-radius: 2rem;
    padding: 1rem;
  }
}

.ui-tooltip .ui-tooltip-text {
  background-color: red;
  color: #fff;
}


.main-site-container {
  margin-left: 82px;

  @media(max-width: 767px) {
    margin-left: 0;
  }
}

.action-task-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 115px;
  margin-top: 10px;
  padding: 12px 18px;
  border-radius: 20px;
  background-color: white;
}

.action-title {
  font-size: 16px;
  font-weight: bold;
  color: #2551BF;
}

.action-author-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.action-author-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}

.action-user-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.actions-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}


// switch (toggle input)
#switch[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

#switch-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 40px;
  background: #F0F3FA 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px #00000029;
  display: block;
  border-radius: 100px;
  position: relative;
}

#switch-label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: gray;
  border-radius: 90px;
  transition: 0.3s;
}

#switch:checked + #switch-label {
  background: #F0F3FA 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px #00000029;

}

#switch:checked + #switch-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background: #00BD82;
}

#switch-label:active:after {
  width: 60px;
}


.right-side .select-workspace-dropdown-container .p-dropdown {
  width: 100% !important;
}


.teams-list {
  .mat-card-header-text {
    display: none !important;
  }
}

.p-calendar .p-inputtext{
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  width: 120px !important;
  height: 35px
}
.p-datepicker{
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
}

.p-datepicker-group{
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
}
.avatar {
  position: relative;

  .user-picture {
    width: 6.5rem;
    object-fit: cover;
    height: 6.5rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto 0 1.9rem;
    cursor: pointer;
  }
}

.user-teams-menu {
  .mat-menu-content {
    max-height: 16rem;
  }
}

.country{
  .flag-icon{
    border-radius: .5rem;
  }
}

.team-card-small {
  position: sticky;
  top: 1rem;
  overflow: unset;
}
